<template>
    <div class="panel">
        <div class="panel-heading">
            {{ versions.length }} {{ versions.length == 1 ? 'Version' : 'Versions' }}
        </div>
        <spinner :active="loading"
            type="cog"
            size="large"></spinner>
        <template v-if="!loading">
            <div class="panel-block"
                v-for="version in filteredVersions"
                :key="version.id">
                <div class="mb-3">
                    <span v-if="version.isWip"
                        class="is-size-6">WIP Version</span>
                    <span v-else
                        class="is-size-6">V{{ version.version }}</span>
                    <span v-if="version.isCurrent"
                        class="is-size-6 is-pulled-right">
                        <span class="mdi mdi-check-bold"></span>
                        Current Version
                    </span>
                    <span class="is-size-7 ml-2">Created {{ version.created.fromNow() }}</span>
                </div>
                <div v-for="file in version.files"
                    :key="file.id">
                    <a :href="file.downloadUrl">
                        {{ file.applicationFormat }} format
                    </a>
                    <span class="is-size-7">({{ file.filesize }})</span>
                </div>
                <div class="tags">
                    <span :class="{ 'is-info': version.state != 'X', 'is-danger': version.state == 'X' }"
                        class="tag">
                        State: {{ version.stateName }}
                    </span>
                    <a @click="transition(state, version)"
                        v-for="state in getStates(version).targets"
                        class="tag">
                        {{ getStateObject(state).action }}
                    </a>
                    <a class="tag"
                        @click="deleteVersion(version)"
                        v-if="!version.isCurrent">
                        Delete
                    </a>
                </div>
            </div>
            <div class="panel-block">
                <span class="is-size-7">
                    <a @click="showAll = true"
                        v-if="!showAll">Show all versions</a>
                    <a @click="showAll = false"
                        v-if="showAll">Show current and WIP versions</a>
                </span>
            </div>
            <div class="panel-block"
                v-if="content.application?.code == 'R' && !hasWipVersion">
                <div class="dropdown is-hoverable">
                    <div class="dropdown-trigger">
                        <button class="button"
                            aria-haspopup="true"
                            aria-controls="dropdown-menu">
                            <span>Create new WIP Version</span>
                            <span class="icon is-small">
                                <i class="mdi mdi-chevron-down"
                                    aria-hidden="true"></i>
                            </span>
                        </button>
                    </div>
                    <div class="dropdown-menu"
                        id="dropdown-menu"
                        role="menu">
                        <div class="dropdown-content">
                            <a @click="addNewVersion(format)"
                                class="dropdown-item"
                                v-for="format in applicationFormats">
                                {{ format }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel-block"
                v-else-if="content.application.code != 'R'">
                <file-upload :max-files="1"
                    :show-filenames="true"
                    :show-preview="true"
                    :upload-url="uploadUrl"
                    instruction="Drag content file here"
                    upload-method="patch"
                    @complete="showUpload = false; loadVersions()"
                    v-if="showUpload">
                </file-upload>
                <button class="button"
                    @click="showUpload = true"
                    v-else>Add new version
                </button>
            </div>
        </template>
    </div>
    <teleport to="body">
        <confirm-dialog ref="confirmDialog"></confirm-dialog>
    </teleport>
</template>

<script>
import { STATES_MODERATED, STATES_UNMODERATED } from "@/constants";
import ConfirmDialog from "@/core/components/ConfirmDialog";
import FileUpload from "@/core/components/FileUpload";
import Spinner from "@/core/components/Spinner";
import http from "@/http";
import { showMessage } from "@/utils";

export default {
    props: ["content"],
    components: { Spinner, FileUpload, ConfirmDialog },
    data() {
        return {
            versions: [],
            user: null,
            loading: true,
            showAll: false,
            showUpload: false,
        };
    },
    async mounted() {
        await this.$store.dispatch("content/loadVersions", this.content.id);
        await this.loadVersions();
        await this.$store.dispatch("users/loadCurrentUser");
        this.user = this.$store.state.users.currentUser;
    },
    methods: {
        async addNewVersion(format) {
            const ok = await this.$refs.confirmDialog.show({
                title: 'Add new version',
                message: 'Are you sure you want to add a new version? ',
            });

            if (!ok)
                return

            this.loading = true;
            let data = {
                content_id: this.content.id,
                application_format: format,
                user_id: this.user.id,
            };
            await http.post(`${process.env.VUE_APP_API_URL_V2}/content/versions/`, data);
            await this.loadVersions();
        },
        async loadVersions() {
            this.loading = true;
            await this.$store.dispatch("content/loadVersions", this.content.id);
            this.versions = this.$store.getters["content/getVersions"](this.content.id);
            this.loading = false;
        },
        async deleteVersion(version = this.currentVersion) {

            if (version.isCurrent) {
                showMessage("You cannot delete the current version", "danger");
                return;
            }

            const ok = await this.$refs.confirmDialog.show({
                title: 'Delete Version',
                message: 'Are you sure you want to delete this version? ',
            });

            if (!ok)
                return

            this.loading = true;
            await http.delete(`${process.env.VUE_APP_API_URL_V2}/content/versions/${version.id}/`);
            await this.loadVersions();
        },
        async transition(state, version = this.currentVersion) {
            const message = this.versionStateMessage(state);
            const ok = await this.$refs.confirmDialog.show({
                title: 'Transition',
                message,
            });

            if (!ok)
                return

            this.loading = true;
            await http.patch(`${process.env.VUE_APP_API_URL_V2}/content/versions/${version.id}/`, { state });
            await this.loadVersions();
        },
        getStateObject(state) {
            return STATES_MODERATED[state];
        },
        getStates(version) {
            let states = this.content.library?.enableWorkflows
                ? STATES_MODERATED[version.state]
                : STATES_UNMODERATED[version.state];
            return states;
        },
        versionStateMessage(state) {
            if (this.content.library.enableWorkflows) {
                const extraBit = state == 'I'
                    ? "and assign yourself as the editor. You may then proceed into Revit and use Content Manager to edit the file. Are you sure you want to continue?"
                    : ". Are you sure you want to continue?";
                return `This will change the item to ${STATES_MODERATED[state].name} ${extraBit}`
            } else {
                const extraBit = state == 'P'
                    ? " and make it the current version. Are you sure you want to continue?"
                    : ". Are you sure you want to continue?";
                return `This will change the item to ${STATES_UNMODERATED[state].name} ${extraBit}`
            }
        },
    },
    computed: {
        hasWipVersion() {
            return this.versions.some(x => x.isWip);
        },
        highestVersion() {
            return this.versions[0];
        },
        applicationFormats() {
            return this.highestVersion.files.map(x => x.applicationFormat);
        },
        filteredVersions() {
            if (!this.versions)
                return [];
            return this.showAll
                ? this.versions
                : this.versions.filter(x => x.isWip || x.isCurrent);
        },
        uploadUrl() {
            return `${process.env.VUE_APP_API_URL_V2}/content/${this.content.id}/`;
        },
        currentVersion() {
            return this.versions.find(version => version.isCurrent) || null;
        },
        isLoaded() {
            return this.content && typeof this.content.application === 'object';
        },
    }
};
</script>

<style lang="sass" scoped>
    .panel-block
        display: block
</style>